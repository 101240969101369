import * as React from "react";
import "./Provider.css";
import logo from "../../../assets/logo.png";
import * as taskController from "../../task/controller";
import { Task, TaskStatus } from "../../../_models/Task";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import * as Moment from "moment-timezone";
const Loader = require("react-loader-spinner").default;
const signatureCanvas = require("react-signature-canvas");
import socket from "../../../socket";

export default class Provider extends React.Component<any, any> {
  token: string;
  id: number;
  subId: number;
  sendChatNote: any;
  sigCanvas: any;
  inputFileElement: any;
  expenseFileElement: any;
  chat: any;
  textBox: any;
  chatLines: number;
  pickup: any[] = [];
  dropoff: any[] = [];
  middle: any[] = [];
  notes: any[] = [];
  handleChatChange: any;
  constructor(props: any) {
    super(props);
    this.state = {
      methods: require(process.env.REACT_APP_DOMAIN
        ? "../../../assets/methods"
        : "../../../assets/methods_dev"),
      task: null,
      loading: false,
      destination: "",
      signature: {
        name: "",
      },
      expense: {
        name: "",
        description: "",
        amount: 0,
        method: null,
        payer: null,
        forWhom: null,
      },
      expenseFileName: false,
      signatureOpened: false,
      expenseOpened: false,
      uploadingImage: false,
      uploadingSignature: false,
      uploadingExpense: false,
    };
    this.token = this.props.match.params.token;
    this.eta = this.eta.bind(this);
    this.start = this.start.bind(this);
    this.end = this.end.bind(this);
    this.stop = this.stop.bind(this);
    this.onLocation = this.onLocation.bind(this);
    this.sendChatNote = taskController.sendChatNote.bind(this);
    this.editAddress = this.editAddress.bind(this);
    this.addGoogleListener = this.addGoogleListener.bind(this);
    this.locationError = this.locationError.bind(this);
    this.handleChatChange = taskController.handleChatChange.bind(this);
  }
  componentDidMount() {
    socket.on("client_update", (data: any) => {
      let {task, notes} = this.state.task;
      if (data.note && data.taskId && data.taskId == task.id) {
        notes.push(data.note);
        this.setState({ task });
      } else if (data.task && data.task.id == task.id) {
        task = new Task(data.task);
        this.setState({ task });
      }
    });
  }
  

  componentWillMount() {
    fetch("public/getTaskByProviderToken", {
      method: "POST",
      body: JSON.stringify({
        id: this.props.match.params.id,
        token: this.token,
      }),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let notes = data.task.notes;
        let task = new Task(data.task);
        this.id = task.provider ? task.provider.id : null;
        this.subId = task.subProvider ? task.subProvider.id : null;
        this.setState({ task: task, notes: notes });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  getLocationETA(destination: string) {
    this.setState({ destination: destination, loading: true }, () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.eta, this.locationError);
        window.open(
          "https://maps.google.com/?q=" +
            this.state.destination +
            "&navigate=yes",
          "_blank"
        );
      } else {
        toast.error(
          "Geolocation is not supported by this browser! change browser"
        );
      }
    });
  }

  getLocationStart() {
    this.setState({ loading: true });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.start, this.locationError);
    } else {
      toast.error(
        "Geolocation is not supported by this browser! change browser"
      );
    }
  }

  getLocationEnd() {
    this.setState({ loading: true });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.end, this.locationError);
    } else {
      toast.error(
        "Geolocation is not supported by this browser! change browser"
      );
    }
  }

  getLocationStop() {
    this.setState({ loading: true });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.stop, this.locationError);
    } else {
      toast.error(
        "Geolocation is not supported by this browser! change browser"
      );
    }
  }

  getLocation() {
    this.setState({ loading: true });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.onLocation,
        this.locationError
      );
    } else {
      toast.error(
        "Geolocation is not supported by this browser! change browser"
      );
    }
  }

  locationError(error: any) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        toast.error(
          "Denied the request for Geolocation. Check your phone settings"
        );
        break;
      case error.POSITION_UNAVAILABLE:
        toast.error("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        toast.error("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        toast.error("An unknown error occurred. Check your phone settings");
        break;
    }
    this.setState({ loading: false });
  }

  promptNotifications(type: string) {
    let prompt = false;
    this.state.task.notifications.map((notification: any) => {
      if (
        notification[type] &&
        (notification.phoneEnabled || notification.emailEnabled)
      )
        prompt = true;
    });

    if (prompt) {
      return confirm("Send client your ETA?");
    } else {
      return false;
    }
  }

  eta(pos: any) {
    if (this.state.task.status != TaskStatus.active) {
      toast.error("Can't send ETA. Task must be active");
      return;
    }

    let sendNotification = this.promptNotifications("eta");

    let location = [pos.coords.latitude, pos.coords.longitude];
    fetch("public/eta", {
      method: "POST",
      body: JSON.stringify({
        lat: location[0],
        lng: location[1],
        destination: this.state.destination,
        token: this.token,
        id: this.id,
        sendNotification,
      }),
    })
      .then((results) => {
        this.setState({ loading: false });
        if (sendNotification) toast.success("We notified the client");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  accept() {
    fetch("public/acceptTask", {
      method: "POST",
      body: JSON.stringify({ id: this.id, token: this.token }),
    })
      .then((results) => {
        let task = this.state.task;
        task.status = TaskStatus.assigned;
        this.setState({ task: task, loading: false });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  decline() {
    let confirmed = confirm("Are you sure?");
    if (!confirmed) return;
    fetch("public/declineTask", {
      method: "POST",
      body: JSON.stringify({ id: this.id, token: this.token }),
    })
      .then(() => {
        this.setState({ task: null, loading: false });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  start(pos: any) {
    let location = [pos.coords.latitude, pos.coords.longitude];
    fetch("public/startTask", {
      method: "POST",
      body: JSON.stringify({
        id: this.id,
        token: this.token,
        lat: location[0],
        lng: location[1],
      }),
    })
      .then((results) => {
        let task = this.state.task;
        task.status = TaskStatus.active;
        this.setState({ task: task, loading: false });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  onLocation(pos: any) {
    let sendNotification = this.promptNotifications("location");
    let location = [pos.coords.latitude, pos.coords.longitude];
    fetch("public/onLocation", {
      method: "POST",
      body: JSON.stringify({
        lat: location[0],
        lng: location[1],
        token: this.token,
        id: this.id,
        sendNotification,
      }),
    })
      .then((results) => {
        this.setState({ loading: false });
        if (sendNotification) toast.success("We notified the client");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  end(pos: any) {
    let location = [pos.coords.latitude, pos.coords.longitude];
    fetch("public/completeTask", {
      method: "POST",
      body: JSON.stringify({
        id: this.id,
        token: this.token,
        lat: location[0],
        lng: location[1],
      }),
    })
      .then((results) => {
        let task = this.state.task;
        task.status = TaskStatus.completed;
        this.setState({ task: task, loading: false });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  stop(pos: any) {
    let location = [pos.coords.latitude, pos.coords.longitude];
    fetch("public/stopClock", {
      method: "POST",
      body: JSON.stringify({
        id: this.id,
        token: this.token,
        lat: location[0],
        lng: location[1],
      }),
    })
      .then((results) => {
        return results.json();
      })
      .then((data: any) => {
        let task = data.task;
        this.setState({ task, loading: false });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  saveSignature() {
    this.setState({ loading: true });
    let signature = this.state.signature;
    signature.data = this.sigCanvas.toDataURL();
    fetch("public/saveSignature", {
      method: "POST",
      body: JSON.stringify({ id: this.id, token: this.token, signature }),
    })
      .then(() => {
        this.setState({ signatureOpened: false, loading: false });
        toast.success("Signature recieved");
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(err.message);
      });
  }

  toggleSignature() {
    this.setState({ signatureOpened: !this.state.signatureOpened });
  }

  toggleExpense() {
    this.setState({ expenseOpened: !this.state.expenseOpened });
  }

  editSignature(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let signature = this.state.signature;
    signature[name] = value;
    this.setState({ signature: signature });
  }

  expenseFileSelected(event: any) {
    let file = this.expenseFileElement.files[0];
    if (!file) return;
    this.setState({ expenseFileName: file.name });
  }

  editExpense(event: any) {
    const value = event.target.value;
    const name = event.target.name;
    let expense = this.state.expense;
    expense[name] = value;
    this.setState({ expense: expense });
  }

  submitExpense(event: any) {
    event.preventDefault();
    let file = this.expenseFileElement.files[0];
    this.setState({
      loading: true,
      uploadingExpense: true,
      expenseOpened: false,
      expenseFileName: "",
    });
    this.expenseFileElement.value = "";
    let formData = new FormData();
    if (file && file.size / 1024 / 1024 > 8) {
      toast.error("Too big. 8MB limit");
      this.setState({ loading: false, uploadingExpense: false });
      return;
    }

    if (!this.state.expense.amount) {
      toast.error("You must enter amount");
      this.setState({ loading: false, uploadingExpense: false });
      return;
    }

    if (file) formData.append("image", file);

    formData.append("token", this.token);
    formData.append("id", this.id.toString());
    formData.append("amount", this.state.expense.amount);

    if (this.state.expense.name)
      formData.append("name", this.state.expense.name);

    if (this.state.expense.description)
      formData.append("description", this.state.expense.description);

    if (this.state.expense.forWhom)
      formData.append("forWhom", this.state.expense.forWhom);

    if (this.state.expense.payer)
      formData.append("payer", this.state.expense.payer);

    if (this.state.expense.method && this.state.expense.payer == "admin")
      formData.append("method", this.state.expense.method);

    fetch("public/saveExpense", {
      method: "POST",
      body: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(() => {
        this.setState({ uploadingExpense: false, loading: false });
        toast.success("Layout submitted");
      })
      .catch((err) => {
        this.setState({ uploadingExpense: false, loading: false });
        toast.error(err.message);
      });
  }

  uploadImage(event: any) {
    event.preventDefault();
    let file = this.inputFileElement.files[0];
    if (!file) return;
    if (file.size / 1024 / 1024 > 8) {
      toast.error("Too big. 8MB limit");
      return;
    }
    this.setState({ uploadingImage: true, loading: true });
    let formData = new FormData();
    formData.append("image", file);
    formData.append("token", this.token);
    formData.append("id", this.id.toString());
    fetch("public/uploadImage", {
      method: "POST",
      body: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(() => {
        this.setState({ uploadingImage: false, loading: false });
        toast.success("Image uploaded");
      })
      .catch((err) => {
        this.setState({ uploadingImage: false, loading: false });
        toast.error(err.message);
      });
  }

  addGoogleListener(element: any) {
    if (!element) return;
    let id = parseInt(element.getAttribute("data-id"));
    if (id) return;
    let index = parseInt(element.getAttribute("data-index"));
    let type = element.getAttribute("data-type") as keyof Provider;
    this[type][index] = element;
    if (this[type][index]) {
      let autocomplete = new window.google.maps.places.Autocomplete(
        this[type][index]
      );
      window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        () => {
          this.editAddress(type, index, this[type][index]);
        }
      );
    }
  }

  editAddress(type: string, index: number, event: any) {
    let target = event.target ? event.target : event;
    let value = target.value;
    let name = target.name;
    let task = this.state.task;
    let address = task[type][index];
    address[name] = value;
    this.setState({ task });
  }

  addAddress(type: string) {
    let task = this.state.task;
    let addresses = task[type];
    addresses.push({
      id: null,
      type,
      name: "",
      address: "",
    });
    this.setState({ task });
  }

  submitAddress(type: string, index: number) {
    this.setState({ loading: true });
    let task = this.state.task;
    let address = task[type][index];
    fetch("public/saveAddress", {
      method: "POST",
      body: JSON.stringify({ id: this.id, token: this.token, address }),
    })
      .then((results) => {
        return results.json();
      })
      .then((data: any) => {
        let task = this.state.task;
        task[type][index].id = data.address.id;
        this.setState({ task, loading: false });
        toast.success("Address updated");
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(err.message);
      });
  }

  removeAddress(type: string, index: number) {
    let confirmed = confirm("Are you sure?");
    if (!confirmed) return;
    this.setState({ loading: true });
    let task = this.state.task;
    let address = task[type][index];
    fetch("public/removeAddress", {
      method: "POST",
      body: JSON.stringify({ id: this.id, token: this.token, address }),
    })
      .then(() => {
        let task = this.state.task;
        task[type].splice(index, 1);
        this.setState({ task, loading: false });
        toast.success("Address removed");
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(err.message);
      });
  }

  public render() {
    let Status = taskController.RenderStatus;
    let {task,notes} = this.state;
    let Chat = taskController.RenderChat;
    let SignatureCanvas = signatureCanvas.default;
    let chatBox = document.getElementById("chat");
    if (chatBox) {
      chatBox.scrollTop = chatBox.scrollHeight;
    }
    let regex = new RegExp(
      /(((\d)\D)?(\(?(\d\d\d)\)?)?\D(\d\d\d)\D(\d\d\d\d))|(\b\d{10,11}\b)/g
    );

    let description = "";

    if (task) {
      description = task.description.replace(regex, (phone: any) => {
        return '<a href="tel:' + phone + '">' + phone + "</a>";
      });
    }

    return (
      <div id="provider-root">
        {this.state.loading && (
          <div className="loadingDiv">
            <Loader type="Oval" color="#e13c34" height="100" width="100" />
          </div>
        )}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnHover={false}
        />
        {task && (
          <div className="container">
            <h4 className="taskStatus">
              Status: {<Status status={task.status} />}
            </h4>
            <h2>
              {Moment(task.date, "MM/DD/YYYY").format("dddd")} {task.date}{" "}
              {task.startTime}
            </h2>
            {task.car && (
              <h5>
                <strong>Vehicle:</strong> {task.car.name}
              </h5>
            )}

            <p className="description">
              <strong>Description:</strong>{" "}
              <em dangerouslySetInnerHTML={{ __html: description }} />
            </p>
            {task.client && (
              <p>
                <strong>Account:</strong> {task.client.name}
              </p>
            )}
            {task.user && (
              <p>
                <strong>Admin:</strong> {task.user.name}
              </p>
            )}

            <div className="addresses">
              {[
                { key: "pickup", value: "Pickup" },
                { key: "middle", value: "Stops" },
                { key: "dropoff", value: "Dropoff" },
              ].map((addressBlock: any, j: number) => {
                return (
                  <div key={j} className="addressBlock">
                    <label>
                      {addressBlock.value}:{" "}
                      <i
                        className="fas fa-plus"
                        onClick={this.addAddress.bind(this, addressBlock.key)}
                      ></i>
                    </label>
                    {task[addressBlock.key].map((address: any, i: number) => {
                      return (
                        <div className="addressContainer" key={i}>
                          {address.id ? (
                            <div>
                              {address.name.length > 0 && (
                                <input
                                  readOnly
                                  name="name"
                                  className="addressName"
                                  value={address.name}
                                  onChange={this.editAddress.bind(
                                    this,
                                    addressBlock.key,
                                    i
                                  )}
                                />
                              )}
                            </div>
                          ) : (
                            <input
                              name="name"
                              className="addressName"
                              value={address.name}
                              placeholder={"Name of the address"}
                              onChange={this.editAddress.bind(
                                this,
                                addressBlock.key,
                                i
                              )}
                            />
                          )}
                          <input
                            name="address"
                            readOnly={address.id || task.archived}
                            className="address"
                            value={address.address}
                            onClick={
                              address.id &&
                              this.getLocationETA.bind(this, address.address)
                            }
                            placeholder="Address"
                            onChange={this.editAddress.bind(
                              this,
                              addressBlock.key,
                              i
                            )}
                            data-index={i}
                            data-id={address.id}
                            data-type={addressBlock.key}
                            ref={this.addGoogleListener}
                          />
                          {!address.id && !task.archived && (
                            <i
                              onClick={this.submitAddress.bind(
                                this,
                                addressBlock.key,
                                i
                              )}
                              className="fas fa-check"
                            ></i>
                          )}
                          {address.id && !task.archived && (
                            <i
                              onClick={this.removeAddress.bind(
                                this,
                                addressBlock.key,
                                i
                              )}
                              className="fas fa-trash"
                            ></i>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>

            <div className="buttonContainer">
              {task.status == TaskStatus.unassigned && (
                <div>
                  <div className="half-btn left red">
                    <button onClick={this.decline.bind(this)}>Decline</button>
                  </div>
                  <div className="half-btn right green">
                    <button onClick={this.accept.bind(this)}>Accept</button>
                  </div>
                </div>
              )}
              {task.status == TaskStatus.assigned && (
                <div>
                  <button
                    className="primary-btn"
                    onClick={this.getLocationStart.bind(this)}
                  >
                    Start Clock
                  </button>
                </div>
              )}

              {task.status == TaskStatus.active && (
                <div>
                  <div className="half-btn left yellow">
                    <button onClick={this.getLocation.bind(this)}>
                      On Location
                    </button>
                  </div>
                  <div className="half-btn right green">
                    <button onClick={this.getLocationEnd.bind(this)}>
                      Completed
                    </button>
                  </div>
                </div>
              )}

              {task.status == TaskStatus.completed && !task.endTime && (
                <div>
                  <button
                    className="primary-btn"
                    onClick={this.getLocationStop.bind(this)}
                  >
                    Stop Clock
                  </button>
                </div>
              )}
            </div>

            {(task.status == TaskStatus.active ||
              task.status == TaskStatus.completed) &&
              !task.archived && (
                <div>
                  <button
                    disabled={this.state.uploadingExpense}
                    className="primary-btn white left"
                    onClick={this.toggleExpense.bind(this)}
                  >
                    {this.state.uploadingExpense ? "Uploading" : "Enter Layout"}
                  </button>

                  {this.state.expenseOpened && (
                    <div className="expense">
                      <div className="container">
                        <a onClick={this.toggleExpense.bind(this)}>
                          Close <i className="fa fa-times"></i>
                        </a>
                        <input
                          name="name"
                          placeholder="Name"
                          onChange={this.editExpense.bind(this)}
                        />
                        <input
                          name="description"
                          placeholder="Description"
                          onChange={this.editExpense.bind(this)}
                        />
                        <input
                          type="text"
                          name="amount"
                          placeholder="Amount"
                          onChange={this.editExpense.bind(this)}
                        />

                        <select
                          name="payer"
                          onChange={this.editExpense.bind(this)}
                          defaultValue="payer"
                        >
                          <option value="payer" disabled>
                            Payment Method used
                          </option>
                          <option value="admin">ServeTie</option>
                          <option value="provider">Personal</option>
                        </select>

                        {this.state.expense.payer == "admin" && (
                          <select
                            name="method"
                            onChange={this.editExpense.bind(this)}
                            defaultValue="method"
                          >
                            <option value="method" disabled>
                              Method used
                            </option>
                            {this.state.methods.map((method: any) => {
                              return (
                                <option value={method.value}>
                                  {method.label}
                                </option>
                              );
                            })}
                          </select>
                        )}

                        <select
                          name="forWhom"
                          onChange={this.editExpense.bind(this)}
                          defaultValue="forWhom"
                        >
                          <option value="forWhom" disabled>
                            Layout type
                          </option>
                          <option value="company">Company</option>
                          <option value="client">Client</option>
                        </select>

                        <input
                          onClick={() => {
                            this.expenseFileElement.click();
                          }}
                          type="button"
                          value={
                            this.state.expenseFileName
                              ? this.state.expenseFileName
                              : "Choose file"
                          }
                        />
                        <button
                          className="primary-btn"
                          onClick={this.submitExpense.bind(this)}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}

                  <input
                    onChange={this.uploadImage.bind(this)}
                    style={{ display: "none" }}
                    type="file"
                    ref={(input) => (this.inputFileElement = input)}
                  />

                  <input
                    onChange={this.expenseFileSelected.bind(this)}
                    style={{ display: "none" }}
                    type="file"
                    ref={(input) => (this.expenseFileElement = input)}
                  />

                  <button
                    disabled={this.state.uploadingImage}
                    className="primary-btn white right"
                    onClick={() => {
                      this.inputFileElement.click();
                    }}
                  >
                    {this.state.uploadingImage ? "Uploading" : "Upload Image"}
                  </button>

                  <button
                    onClick={this.toggleSignature.bind(this)}
                    className="primary-btn signatureOpen"
                  >
                    Receiver's Signature <i className="fa fa-pen"></i>
                  </button>

                  {this.state.signatureOpened && (
                    <div className="signature">
                      <div className="container">
                        <a onClick={this.toggleSignature.bind(this)}>
                          Close <i className="fa fa-times"></i>
                        </a>
                        <input
                          name="name"
                          placeholder="Name"
                          onChange={this.editSignature.bind(this)}
                        />
                        <SignatureCanvas
                          penColor="black"
                          ref={(ref: any) => {
                            this.sigCanvas = ref;
                          }}
                          canvasProps={{
                            width: window.innerWidth * 0.9 * 0.9,
                            height: 150,
                            className: "sigCanvas",
                          }}
                        />
                        <button
                          className="primary-btn"
                          onClick={this.saveSignature.bind(this)}
                        >
                          Save signature
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}

            <div
              id="chat"
              className="chat"
              ref={(e: any) => {
                this.chat = e;
              }}
            >
              <Chat key={notes ? notes.length : 0} task={task} notes={notes || []} userMode={false} />
            </div>
            {!task.archived && (
              <div className="newMsgContainer">
                <textarea
                  name="newMessage"
                  placeholder="Type note..."
                  onChange={this.handleChatChange.bind(this, 30)}
                  onKeyDown={taskController.keyPress.bind(
                    this,
                    this.id,
                    this.subId,
                    30
                  )}
                  ref={(e: any) => {
                    this.textBox = e;
                  }}
                  style={{ height: "50px" }}
                ></textarea>

                <span
                  onClick={taskController.sendChatNote.bind(
                    this,
                    this.id,
                    this.subId
                  )}
                  className="sendMsg"
                >
                  Send
                </span>
              </div>
            )}

            <img className="logo" src={logo} />
          </div>
        )}

        {!task && (
          <div className="container">
            <h1>This task is not avaliable for you.</h1>
            <img className="logo" src={logo} />
          </div>
        )}
      </div>
    );
  }
}
